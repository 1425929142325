import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/createReduxStore';
import { useFirestoreConnect } from 'react-redux-firebase';
import GenericTable from '../../../../components/GenericTable';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { removeUser } from '../../../../store/actions/adminActions';
import Dialog from '../../../../components/Dialog';
import StudentImport from '../../../../components/StudentImport';
import { AdminRoutes } from '../../../../_CONS/routes';

const newCols = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'First',
    selector: 'firstName',
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Last',
    selector: 'lastName',
  },
  {
    id: 'state',
    align: 'left',
    disablePadding: false,
    label: 'Email',
    selector: 'email',
  },
];

type TProps = {};

const List = (props: TProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [userToDelete, setUserToDelete] = useState(null);

  const organization = useSelector((state: RootState) => state.firestore.data.currentOrganization);
  const students = useSelector((state: RootState) => state.firestore.ordered.organizationStudents);
  const toConnect = organization ? [
    {
      collection: 'users',
      storeAs: 'organizationStudents',
      where: [
        ['organization', '==', organization.id],
        ['role', '==', 'student']
      ],
    },
  ] : [];

  //@ts-ignore
  useFirestoreConnect(toConnect)

  const onMenuAction = (e: any, id: string, action: string) => {
    if (action === 'view') {
      history.push(`${AdminRoutes.ORGANIZATIONS}/view/${organization.id}/student/view/${id}`);
    } else if (action === 'delete') {
      setUserToDelete(id);
    }
  }

  const onCloseDialog = () => {
    if (userToDelete) {
      setUserToDelete(null);
    }
  }

  const onConfirmDialog = (type) => {
    dispatch(removeUser(userToDelete));
    setUserToDelete(null);
  }

  return (
    <Grid container spacing={3}>
      <Dialog
        type={'confirm'}
        title={'Confirm'}
        content={'Are You sure You want to delete the student?'}
        open={userToDelete}
        onCancel={onCloseDialog}
        onConfirm={onConfirmDialog}
        buttonLabels={['CANCEL', 'DELETE']}
      />

      <StudentImport organizationId={organization.id} />

      <Grid item xs={12}>
        <GenericTable
          menuItems={['view', 'delete',]}
          colHeaders={newCols}
          data={students}
          onMenuAction={onMenuAction}
        />
      </Grid>

    </Grid>
  );
}

export default List;
