import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MuiDivider from '@material-ui/core/Divider';
import { formatDateTime } from '../../../../utils/formatDateTime';
import { MenuItem, Select, TextField, Typography } from '@material-ui/core';
import Button from '../../../../elements/Button';
import { TOrganization } from '../../../../../types';
import usStates from '../../../../_CONS/usStates';
import { TUser } from '../../../../store/types';
import { capitalize } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganization } from '../../../../store/actions/organizationsActions';
import types from '../../../../_CONS/organizationTypes';
import styled from 'styled-components/macro';
import LoadingModal from '../../../../components/LoadingModal';
import { RootState } from '../../../../store/createReduxStore';
import { formatPhoneNumber } from '../../../../utils/format';

type TProfile = {
  avatarFile?: any,
  organization: TOrganization,
  organizationUsers: TUser[],
}

const Divider = styled(MuiDivider)`
  margin: 1rem 0;
`;

const Profile: React.FC<TProfile> = ({
  avatarFile,
  organization,
  organizationUsers,
  ...props
}) => {
  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.organizationsReducer.status);
  const profile = useSelector((state: RootState) => state.firebase.profile);

  const [contactUserInfo, setContactUserInfo] = useState({
    phoneNumber: '',
    jobTitle: '',
    email: '',
  });
  const [fields, setFields] = useState({
    name: '',
    addressLine1: '',
    type: '',
    state: '',
    city: '',
    zip: '',
    primaryContact: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    addressLine1: '',
    type: '',
    state: '',
    city: '',
    zip: '',
  });

  useEffect(() => {
    const fields = {
      name: organization.name || '',
      addressLine1: organization.addressLine1 || '',
      type: organization.type || '',
      state: organization.state.toLowerCase() || '',
      city: organization.city || '',
      zip: organization.zip || '',
      primaryContact: '',
    };

    if (organizationUsers.length && organization.primaryContact) {
      fields.primaryContact = organization.primaryContact;
    }

    setFields(fields)
  }, [organization, organizationUsers]);

  useEffect(() => {
    if ((organization.primaryContact || fields.primaryContact) && organizationUsers.length) {
      const result = {
        phoneNumber: '',
        jobTitle: '',
        email: '',
        ...organizationUsers.find(({ uid }) => uid === fields.primaryContact)
      }
      setContactUserInfo(result)
    }
  }, [organization, organizationUsers, fields.primaryContact]);

  const onChange = (name: string) => (e: any) => {
    let val = e.target.value;

    setFields({
      ...fields,
      [name]: val
    })
  };

  const onUpdate = () => {
    dispatch(updateOrganization({
      ...organization,
      ...fields
    }, avatarFile))
  }

  return (
    <form {...props}>
      <LoadingModal open={status === 'loading'} />
      <div>
        <Typography variant="subtitle2" gutterBottom align="right">Record Last Updated: {formatDateTime(organization.updatedDate)}</Typography>
        <Typography variant="h6" gutterBottom>Account Details</Typography>

        <Grid container spacing={3} direction={'column'}>
          <Grid item container justify={'space-between'}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Organization Name"
                error={!!errors.name}
                helperText={errors.name}
                value={fields.name}
                inputProps={{ maxLength: 255 }}
                onChange={onChange('name')}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant={'body2'} align={'right'}>Creation Date/Time</Typography>
              <Typography variant={'body2'} align={'right'}>{formatDateTime(organization.updatedDate)}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Type</InputLabel>

              <Select value={fields.type} onChange={onChange('type')}>
                {
                  types.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address"
              error={!!errors.addressLine1}
              helperText={errors.addressLine1}
              value={fields.addressLine1}
              inputProps={{ maxLength: 255 }}
              onChange={onChange('addressLine1')}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="City"
              error={!!errors.city}
              helperText={errors.city}
              value={fields.city}
              inputProps={{ maxLength: 255 }}
              onChange={onChange('city')}
            />
          </Grid>

          <Grid item container xs={6} alignItems={'flex-end'} justify={'space-between'}>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                  fullWidth
                  labelId="state"
                  name={'state'}
                  value={fields.state}
                  onChange={onChange('state')}
                >
                  {
                    usStates.map((state) => (
                      <MenuItem key={state} value={state.toLowerCase()}>
                        {state.toUpperCase()}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Zip"
                error={!!errors.zip}
                helperText={errors.zip}
                value={fields.zip}
                inputProps={{ maxLength: 255 }}
                onChange={onChange('zip')}
              />
            </Grid>
          </Grid>
        </Grid>

      </div>

      <Divider />

      <div>
        <Typography variant="h6" gutterBottom>Primary Contact Information</Typography>

        <Grid container spacing={3} direction={'column'}>
          <Grid item xs={6}>
            <FormControl margin="dense" fullWidth>
              <Select
                disabled={!!profile.token.claims.organization}
                displayEmpty
                value={fields.primaryContact}
                onChange={onChange('primaryContact')}
              >
                <MenuItem value={''} disabled>Select user</MenuItem>
                {
                  organizationUsers.map(({ uid, firstName, lastName }) => (
                    <MenuItem key={uid} value={uid}>{capitalize(`${firstName} ${lastName}`)}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="Job Description/Title"
              value={contactUserInfo.jobTitle}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="Mobile Phone Number"
              name="phone"
              value={formatPhoneNumber(contactUserInfo.phoneNumber)}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="Email Address"
              name="email"
              value={contactUserInfo.email}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div>
        <Grid container direction="row" justify="space-between">
          <Button
            onClick={onUpdate}
            variant="contained"
            color="primary"
            data-testid="Update Organization"
          >
            Update
          </Button>

        </Grid>
      </div>
    </form>
  )
}

export default Profile;
