import {TStudent} from '../../../types';
import {actionCreator} from '../../utils/actionCreator';
import {getAsyncActions} from '../../utils/getAsyncActions';
import firebase from '../api/firebaseAdmin';
import {TAsyncAction, TDispatch, TNewStudent} from '../types';
import {showAlert} from './alertActions';
import {firestore} from "firebase";

export type TImportRow = {
  'Student First': string,
  'Student Last': string,
  'School Email': string,
  'Zip Code': string,
  'Phone Number': string,
};

type TUserActions = {
  RESET: string,
  CREATE: TAsyncAction,
  DELETE: TAsyncAction,
  UPGRADE: TAsyncAction,
  UPDATE: TAsyncAction,
  SEND_EMAIL: TAsyncAction,
  IMPORT: TAsyncAction,
};

export const UserActions: TUserActions = {
  RESET: 'user/reset',
  ...getAsyncActions({
    CREATE: 'user/create',
    DELETE: 'user/delete',
    UPGRADE: 'user/upgrade',
    UPDATE: 'user/update',
    SEND_EMAIL: 'user/sendEmail',
    IMPORT: 'user/import',
  })
} as TUserActions;

export const resetStudentView = () => ({
  type: UserActions.RESET,
});

export const importStudents = (file: File, inputStudents: TImportRow[], organizationId: string, onDone: Function) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.IMPORT.REQUESTED));
    let error = '';
    const normalizedStudents = [];
    const organization = await firestore().collection("organizations").doc(organizationId).get().then((doc) => doc.data())
    for (let index = 0; index < inputStudents.length; index++) {
      const firstName = inputStudents[index]['Student First'];
      const lastName = inputStudents[index]['Student Last'];
      const email = inputStudents[index]['School Email'];
      
      if (!firstName) {
        error = 'The first name should not be empty.';
      } else if (!lastName) {
        error = 'The last name should not be empty.';
      } else if (!email) {
        error = 'The email should not be empty.';
      }
      
      if (error) {
        dispatch(actionCreator(UserActions.IMPORT.FAILURE, error, true));
        
        dispatch(showAlert({
          title: 'Error',
          message: error,
        }));
        return;
      }
      
      normalizedStudents.push({
        firstName,
        lastName,
        email,
        zip: inputStudents[index]['Zip Code'],
        phone: inputStudents[index]['Phone Number'].replace(/\D/g, ''),
        password: Math.random().toString(36).slice(-8),
        accountType: !!organization.willFundPro ? 'pro' : 'trial',
        accountLevel: !!organization.willFundPro ? 'pad' : 'free',
      });
    }
    
    const students = normalizedStudents.map(student => ({
      ...student,
      organization: organizationId,
    }));
    
    try {
      const fileName = file.name.replace(/\.\w+$/, '') + '_' + new Date().getTime();
      const fileExtension = file.name.replace(/^.*\./, '');
      
      firebase.storage().ref().child(`imports/${organizationId}/${fileName}.${fileExtension}`).put(file);
    } catch (e) {
      console.log(e);
    }
    
    try {
      const importStudentsFunc = firebase.functions().httpsCallable('importStudents');
      const {data} = await importStudentsFunc(students);
      
      if (data.success) {
        dispatch(actionCreator(UserActions.IMPORT.SUCCESS));
        
        dispatch(showAlert({
          title: 'Success',
          message: 'The Students have been created.',
        }));
        if (onDone) {
          onDone([]);
        }
      } else {
        dispatch(actionCreator(UserActions.IMPORT.FAILURE, 'Not all students have been created. Check out the report.', true));
        dispatch(showAlert({
          title: 'Error',
          message: 'Not all students have been created. Check out the report.',
        }));
        if (onDone) {
          onDone(data.data.filter(item => item.status === 'rejected'));
        }
      }
    } catch (e) {
      dispatch(actionCreator(UserActions.IMPORT.FAILURE, e.message || JSON.stringify(e), true));
    }
  };
};

export const createNewStudent = (student: TNewStudent) => {
  return async (dispatch: TDispatch) => {
    const createNewStudent = firebase.functions().httpsCallable('createNewStudent');
    
    dispatch(actionCreator(UserActions.CREATE.REQUESTED));
    
    return createNewStudent(student).then(({data: {success, error}}) => {
      if (!success) {
        throw new Error(error)
      } else {
        dispatch(actionCreator(UserActions.CREATE.SUCCESS));
        
        dispatch(showAlert({
          title: 'Success',
          message: 'The Student has been successfully created.',
        }));
      }
    }).catch((error) => {
      dispatch(actionCreator(UserActions.CREATE.FAILURE, error.message || JSON.stringify(error), true));
      
      dispatch(showAlert({
        title: 'Error',
        message: error.message || 'The student has not been created.',
      }));
    });
  };
};

export const removeStudent = (uid: string) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.DELETE.REQUESTED));
    
    try {
      const removeStudent = firebase.functions().httpsCallable('removeStudent');
      const {data} = await removeStudent(uid);
      
      if (data.success) {
        dispatch(actionCreator(UserActions.DELETE.SUCCESS));
        
        dispatch(showAlert({
          title: 'Success',
          message: 'The Student has been successfully deleted.',
        }));
      } else {
        throw (new Error(data.error || JSON.stringify(data.error)))
      }
    } catch (e) {
      dispatch(actionCreator(UserActions.DELETE.FAILURE, e.message || JSON.stringify(e), true));
      
      dispatch(showAlert({
        title: 'Error',
        message: 'The Student has not been deleted.',
      }));
    }
  };
};

export const sendOutNewUserEmail = (student: TStudent) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.SEND_EMAIL.REQUESTED));
    
    try {
      if (student.organization) {
        const sendWelcomeEmail = firebase.functions().httpsCallable('sendWelcomeEmail');
        await sendWelcomeEmail(student);
      } else {
        await firebase.auth().sendPasswordResetEmail(student.email);
      }
      
      dispatch(actionCreator(UserActions.SEND_EMAIL.SUCCESS));
    } catch (err) {
      dispatch(actionCreator(UserActions.SEND_EMAIL.FAILURE, err.message, true));
    }
  };
};

export const updateStudent = (student: Partial<TStudent>, hideAlert = false) => {
  return async (dispatch: TDispatch) => {
    const updateStudentRecord = firebase.functions().httpsCallable('updateStudentRecord');
    dispatch(actionCreator(UserActions.UPDATE.REQUESTED));
    
    try {
      const result = await updateStudentRecord(student);
      
      if (result.data.success) {
        dispatch(actionCreator(UserActions.UPDATE.SUCCESS));
        
        if (!hideAlert) {
          dispatch(showAlert({
            title: 'Success',
            message: 'The Student has been successfully updated.',
          }));
        }
      } else {
        throw result.data.error;
      }
    } catch (err) {
      dispatch(actionCreator(UserActions.UPDATE.FAILURE, typeof err === 'string' ? err : JSON.stringify(err), true));
      
      if (!hideAlert) {
        dispatch(showAlert({
          title: 'Error',
          message: 'The Student was not updated.',
        }));
      }
    }
  };
};

export const bulkStudentsUpdate = (studentIds: string[], changes: { accountType?: string, isActive?: boolean }) => {
  return async (dispatch: TDispatch) => {
    const bulkStudentEdit = firebase.functions().httpsCallable('bulkStudentsUpdate');
    dispatch(actionCreator(UserActions.UPDATE.REQUESTED));
    
    bulkStudentEdit({studentIds, changes}).then(({data}) => {
      const {success} = data;
      if (success) {
        dispatch(actionCreator(UserActions.UPDATE.SUCCESS));
        
        dispatch(showAlert({
          title: 'Success',
          message: 'The Students have been successfully updated.',
        }));
      } else {
        throw new Error('The Students have not been successfully updated.');
      }
    }).catch(err => {
      dispatch(actionCreator(UserActions.UPDATE.FAILURE, err.message || 'Server error happened, try again later', true));
      
      dispatch(showAlert({
        title: 'Error',
        message: 'Server error happened, try again later.',
      }));
    });
  };
};
