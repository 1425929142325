import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
import { useHistory } from 'react-router';
import { useState } from 'react';
import organizationTypes from '../../../../_CONS/organizationTypes';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import AvatarCard from '../../../../components/AvatarCard';
import { useDispatch, useSelector } from 'react-redux';
import { createOrganization } from '../../../../store/actions/organizationsActions';
import usStates from '../../../../_CONS/usStates';
import LoadingModal from '../../../../components/LoadingModal';
import { RootState } from '../../../../store/createReduxStore';
import BackButton from '../../../../elements/BackButton';

type TProps = {
  avatarFile?: any,
}

const New = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector((state: RootState) => state.organizationsReducer.status);

  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState('');
  const [isFilledIn, setFilledInFlag] = useState(false);
  const [fields, setFields] = useState({
    name: '',
    type: '',
    state: '',
    city: '',
    addressLine1: '',
    zip: '',
  });

  useEffect(() => {
    const name = fields.name.replace(/\s/g, '');

    setFilledInFlag(!!name && !!fields.type)
  }, [fields.name, fields.type])

  useEffect(() => {
    if (avatarFile) {
      setAvatarSrc(URL.createObjectURL(avatarFile));
    }
  }, [avatarFile]);

  const goBack = () => {
    history.push('/admin/organizations');
  }

  const onChange = (field: string) => (e: any) => {
    let val = e.target.value;

    setFields({
      ...fields,
      [field]: val
    })
  };

  const onCreate = (e: any) => {
    e.preventDefault();

    dispatch(createOrganization(fields, avatarFile))
  };

  return (
    <MainScreen
      title="New Organization"
      leftSideItem={<BackButton onClick={goBack} />}
      spacing={3}
    >
      <LoadingModal open={status === 'loading'} />
      <Grid item md={4} xs={12}>
        <Grid container spacing={3} direction={'column'}>
          <Grid item>
            <AvatarCard
              avatarSrc={avatarSrc}
              withUploader
              onUpload={setAvatarFile}
              user={{
                initials: '',
                firstName: '',
                lastName: '',
              }}
            />
          </Grid>
          <Grid item />
        </Grid>
      </Grid>

      <Grid item md={8} xs={12}>
        <Card>
          <form onSubmit={onCreate}>
            <CardContent>
              <Grid container spacing={3} direction={'column'}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Organization Name *"
                    value={fields.name}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange('name')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={fields.type}
                    onChange={onChange('type')}
                  >
                    <MenuItem value='' disabled>Type *</MenuItem>
                    {
                      organizationTypes.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    value={fields.addressLine1}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange('addressLine1')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    value={fields.city}
                    inputProps={{ maxLength: 255 }}
                    onChange={onChange('city')}
                  />
                </Grid>

                <Grid item container xs={6} alignItems={'flex-end'} justify={'space-between'}>
                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <InputLabel id="state">State</InputLabel>
                      <Select
                        fullWidth
                        displayEmpty
                        labelId={'state'}
                        name={'state'}
                        value={fields.state}
                        onChange={onChange('state')}
                      >
                        <MenuItem value='' disabled>State</MenuItem>
                        {
                          usStates.map((state) => (
                            <MenuItem key={state} value={state.toLowerCase()}>
                              {state.toUpperCase()}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Zip"
                      value={fields.zip}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange('zip')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    disabled={!isFilledIn}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </MainScreen >
  );
}

export default New;
