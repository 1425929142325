import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import { TabsProps } from 'material-ui';
import styled from 'styled-components/macro';

const StyledTabs = styled(MuiTabs)`
  borderBottom: 1px solid #e8e8e8;

  .indicator {
    backgroundColor: #1890ff;
  }
`;

const Tabs: React.FC<TabsProps> = (props) => (
  <StyledTabs {...props} >
    {props.children}
  </StyledTabs>
);

export default Tabs;
