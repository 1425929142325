import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { Card, CardHeader, CardContent } from '../../containers/Card';
import { minutesToTime } from '../../utils/formatDateTime';
import Button from '../../elements/Button';
import theme from '../../styles/theme';
import {
  TestCard,
  Stat,
  TimeStat,
  Label,
  TimeLabel,
} from './StyledTestGridBox';

const TestGridBox = props => {
  
  return (
    <Grid item container xs={12} sm={6} >
      <Card>
        <TestCard isComplete={props.completed}>
          <CardHeader
            title={props.title}
            action={props.completed && (
              <IconButton>
                <CheckCircle />
              </IconButton>
            )}
          />
          <CardContent paddingVertical={props.item.customTime > 0 ? 0 : theme.spacing(2)}>
            <Grid container spacing={3}>
              <Grid item md={6} container direction='column' justify='center' >
                <Stat>{props.item.questionsTotal}</Stat>
                <Label>Questions</Label>
              </Grid>
              {
                props.item.customTime ? (
                  <Grid container item md={6} direction='column' justify='space-between' spacing={2}>
                    <Grid item >
                      <TimeStat>
                        {minutesToTime(props.item.customTime)}
                      </TimeStat>
                      <TimeLabel>TIME ALLOTTED</TimeLabel>
                    </Grid>
                    <Grid item>
                      <TimeStat>
                        {minutesToTime((props.item.customTime * 60000 - props.item.duration) / 60000)}
                      </TimeStat>
                      <TimeLabel>TIME REMAINING</TimeLabel>
                    </Grid>
                  </Grid>
                ) : null
              }
            </Grid>
          </CardContent>

          <CardContent>
            {!props.completed && (
              props.item.created ? (
                <Button
                  color='default'
                  variant='outlined'
                  onClick={props.guideActionContinue}
                >
                  CONTINUE
                </Button>
              ) : (
                <Button color='primary' onClick={props.guideActionBuild}>
                  START
                </Button>
              )
            )}
          </CardContent>

        </TestCard>
      </Card>
    </Grid>
  );
};

export default TestGridBox;
