import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import IconEdit from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';

import Button from '../../elements/Button';
import { updateQuestion } from '../../store/actions/questionActions';
import { Card, CardHeader, CardContent } from '../../containers/Card';
import SelectClassification from '../../components/SelectClassification';

const styles = theme => ({
  card: {
    display: 'flex',
    width: '100%',
  },
  content: {
    paddingTop: 0,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class CardClassifyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isDirty: false,
      oldSubjectCode: props.subjectCode,
      oldSubject: props.subject,
      oldClassificationArray: Array.isArray(props.classification)
        ? props.classification
        : [],
      oldClassification:
        Array.isArray(props.classification) && props.classification.length > 0
          ? props.classification[0]
          : '',
      newSubjectCode: props.subjectCode,
      newSubject: props.subject,
      newClassificationArray: Array.isArray(props.classification)
        ? props.classification
        : [],
      newClassification:
        Array.isArray(props.classification) && props.classification.length > 0
          ? props.classification[0]
          : '',
      localClassification: {},
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.cancelFieldValue = this.cancelFieldValue.bind(this);
    this.updateLocalClassification = this.updateLocalClassification.bind(this);
  }

  toggleEditMode() {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  }
  cancelFieldValue() {
    const { oldSubjectCode, oldSubject, oldClassificationArray } = this.state;
    this.setState({
      newSubjectCode: oldSubjectCode,
      newSubject: oldSubject,
      newClassificationArray: oldClassificationArray,
      editMode: false,
    });
  }

  updateLocalClassification(uobj) {
    this.setState({ localClassification: uobj });
  }
  saveFieldValue() {
    const { level, qbank, qid } = this.props;
    const {
      oldClassificationArray,
      localClassification,
    } = this.state;

    const { subjectCode, subject, classification } = localClassification;
    oldClassificationArray[0] = classification;
    const uobj = {
      subjectCode,
      subject,
      classification: oldClassificationArray,
    };
    this.props.updateQuestion(level, qbank, qid, uobj).then(() => {
      this.setState({
        editMode: false,
        oldSubjectCode: subjectCode,
        oldSubject: subject,
        oldClassificationArray: oldClassificationArray,
        oldClassification:
          Array.isArray(oldClassificationArray) &&
            oldClassificationArray.length > 0
            ? oldClassificationArray[0]
            : '',
        newSubjectCode: subjectCode,
        newSubject: subject,
        newClassificationArray: oldClassificationArray,
        newClassification:
          Array.isArray(oldClassificationArray) &&
            oldClassificationArray.length > 0
            ? oldClassificationArray[0]
            : '',
        localClassification: {},
      });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, label, type } = this.props;
    const {
      editMode,
      newSubjectCode,
      newSubject,
      newClassification,
      oldClassification,
    } = this.state;

    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardHeader
              title={label}
              action={
                <IconButton
                  onClick={this.toggleEditMode}
                  disabled={newClassification !== oldClassification}
                >
                  <IconEdit />
                </IconButton>
              }
            />
            <CardContent className={classes.content}>
              <SelectClassification
                type={type}
                subjectCode={newSubjectCode}
                classification={newClassification}
                handleUpdate={this.updateLocalClassification}
              />
            </CardContent>
            <CardContent className={classes.actions}>
              <Button
                variant='text'
                color='default'
                onClick={this.cancelFieldValue}
              >
                CANCEL
              </Button>
              <Button
                variant='text'
                color='secondary'
                onClick={this.saveFieldValue}
                disabled={newClassification !== oldClassification}
              >
                SAVE
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardHeader
              title={label}
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor='subjectCode'>
                      Subject Code
                    </InputLabel>
                    <TextField
                      id='subjectCode'
                      value={newSubjectCode}
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                        shrink: 'true',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor='show-subject'>
                      Subject
                    </InputLabel>
                    <TextField
                      id='show-subject'
                      value={newSubject}
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                        shrink: 'true',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor='classification'>
                      Classification
                    </InputLabel>
                    <TextField
                      id='classification'
                      value={newClassification}
                      margin='normal'
                      InputProps={{
                        readOnly: true,
                        shrink: 'true',
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
    );
  }
}

CardClassifyEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  status: state.questionStatus.status,
  qid: state.questionStatus.qid,
});

const mapDispatchToProps = { updateQuestion };

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CardClassifyEdit);
