import { Grid, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components/macro';
import { color } from '../../styles/style';

export const Container = styled(Grid)`
  background-color: ${color.secondaryLight};
  padding: 1rem 0; 
  position: relative;
`;

export const Content = styled(Grid)`
  width: 80%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled(Typography)`
  padding: 0 4em 0 1em
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0
`;

export const Info = styled(Grid)`
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em
`;
