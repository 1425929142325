import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import Button from '../../../elements/Button';
import SimpleLinearProgress from '../../../elements/SimpleLinearProgress';
import * as routes from '../../../_CONS/routes';
import { Card, CardHeader, CardContent } from '../../../containers/Card';
import LogoTall from '../../../assets/logos/JD-Advising_logo-Tall.png';
import { startStudentSetup } from '../../../store/actions/setupActions';
import { Divider, InputLabel, TextField, Typography, Grid } from '@material-ui/core';
import { formatDateTime } from '../../../utils/formatDateTime';
import { formatPhoneNumber } from '../../../utils/format';
import lawSchools from '../../../_CONS/lawSchools';
import { Autocomplete } from '@material-ui/lab';
import { validate, validatePhoneNumber, validateYear, validateZip } from '../../../utils/validator';
import { updateStudent } from '../../../store/actions/studentActions';
import { TStudent } from '../../../../types';
import { SetupScreen, Logo, DataContainer, SectionTitle, ReadOnlyValue, } from './StyledDashboard';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const howHeard = [
  "Google/Online Search",
  "MPRE Course",
  "Above The Law",
  "The National Jurist",
  "Email/Newsletter",
  "Friend/Word of Mouth",
  "Phi Alpha Delta",
  "Social Media (Please Specify The Platform Below)",
  "YouTube",
  "Professor/Law School Faculty (Please Specify Below)",
  "Used Services In The Past",
  "Other (Please Specify Below)",
];

type TProps = PropsFromRedux & RouteComponentProps;

type TFields = {
  zip: string,
  phone: string,
  graduationYear: string,
  school: string | null,
  howHeard: string | null,
  howHeardNotes: string,
}

type TState = {
  isFilled: boolean,
  isFinished: boolean,
  fields: TFields,
  errors: Partial<TFields>,
}

const accountDetails = [
  { field: 'accountType', label: 'Account Type', },
  { field: 'status', label: 'Status' },
  { field: 'startDate', label: 'Start Date' },
];

const studentDetails = [
  { field: 'firstName', label: 'First Name', },
  { field: 'lastName', label: 'Last Name' },
  { field: 'email', label: 'Email' },
  {
    field: 'phone', label: 'Phone Number', editable: true, props: {
      inputProps: { maxLength: 16 }
    }
  },
  {
    field: 'zip', label: 'Zip *', editable: true, props: {
      inputProps: { maxLength: 12 }
    }
  },
  {
    field: 'graduationYear', label: 'Law School Graduation Year *', editable: true, props: {
      inputProps: { maxLength: 4 }
    }
  },
];

class Dashboard extends Component<TProps, TState> {
  constructor(props) {
    super(props);
    this.state = {
      isFilled: false,
      isFinished: false,
      fields: {
        zip: '',
        phone: '',
        graduationYear: '',
        school: null,
        howHeard: null,
        howHeardNotes: ''
      },
      errors: {},
    }
    this.beginStudentSetup = this.beginStudentSetup.bind(this);
  }

  componentDidMount() {
    const profile = this.props.profile;
    const fields = {
      zip: profile.zip || '',
      phone: formatPhoneNumber(profile.phone) || '',
      graduationYear: profile.graduationYear ? profile.graduationYear.toString() : '',
      school: profile.school || null,
      howHeard: profile.howHeard || null,
      howHeardNotes: profile.howHeardNotes || '',
    }

    this.setState({
      isFilled: this.checkRequiredFields(fields),
      fields
    });
  }

  componentDidUpdate(prev: TProps) {
    const profile = this.props.profile;
    const studentOrganization = this.props.studentOrganization;

    if (!prev.profile.isLoaded && profile.isLoaded) {
      const fields = {
        zip: profile.zip || '',
        phone: formatPhoneNumber(profile.phone) || '',
        graduationYear: profile.graduationYear ? profile.graduationYear.toString() : '',
        school: profile.school || null,
        howHeard: profile.howHeard || null,
        howHeardNotes: profile.howHeardNotes || '',
      }

      this.setState({
        isFilled: this.checkRequiredFields(fields),
        fields
      });
    }

    if (prev.profile.status !== 'current' && profile.status === 'current') {
      this.props.finishStudentSetup();
      this.setState({
        isFinished: true
      })
    }

    if (prev.studentOrganization !== studentOrganization && studentOrganization) {
      this.setState(({ fields }) => ({
        fields: {
          ...fields,
          school: lawSchools.find((school) => (
            school.toLowerCase().indexOf(studentOrganization.name.toLowerCase()) >= 0
          )) || '',
        }
      }));
    }
  }

  validateFields = () => {
    const fields = this.state.fields;

    const errors = validate(fields, {
      zip: [validateZip],
      phone: fields.phone ? [validatePhoneNumber] : [],
      graduationYear: [validateYear],
    });

    this.setState({ errors });
    return !Object.keys(errors).length;
  }

  checkRequiredFields = (fields: TFields) => {
    const required = ['zip', 'graduationYear', 'school', 'howHeard'];
    return !required.some((field) => !fields[field]);
  }

  onChange = (name) => (e, data = null) => {
    const { fields } = this.state;
    let value = e.target.value;

    if (name === 'howHeard' || name === 'school') {
      value = data
    } else if (name === 'phone' && value.length > fields.phone.length) {
      value = formatPhoneNumber(value)
    }

    const newFields = {
      ...fields,
      [name]: value,
    };

    this.setState({
      isFilled: this.checkRequiredFields(newFields),
      fields: newFields,
    });
  };

  beginStudentSetup() {
    const { graduationYear, howHeard, howHeardNotes, school, phone, zip } = this.state.fields;
    const { studentId, profile } = this.props;

    const toUpdate = {
      uid: studentId,
      accountType: profile.accountType,
      graduationYear: +graduationYear,
      howHeardNotes,
      howHeard,
      school,
      phone,
      zip
    };

    if (this.validateFields()) {
      this.props.updateStudent(toUpdate, true).then(this.props.startStudentSetup)
    }
  }

  render() {
    const { setupStatus, profile } = this.props;
    const { errors, fields, isFilled, isFinished } = this.state;

    if (isFinished) {
      return <Redirect to={routes.STUDENT} />
    }

    return (
      <SetupScreen>
        <Grid item xs={12}>
          <Logo alt="logo" src={LogoTall} />
          <Typography variant="h1">Welcome to Your MBE QBank</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="body1">
            Your account has been created. Please continue the setup process below.
          </Typography>
        </Grid>

        <DataContainer item xs={12}>
          <Card>
            <CardContent>
              <SectionTitle variant="h6" gutterBottom>Account Details</SectionTitle>

              <Grid container spacing={3}>
                {
                  accountDetails.map(({ field, label }) => (
                    <Grid item xs={12} sm={4} key={field}>
                      <InputLabel shrink>{label}</InputLabel>
                      <ReadOnlyValue data-testid={field}>{profile[field]}</ReadOnlyValue>
                    </Grid>
                  ))
                }
              </Grid>
            </CardContent>

            <Divider />

            <CardContent>
              <SectionTitle variant="h6" gutterBottom>Student Details</SectionTitle>
              <Grid container spacing={3}>
                {
                  studentDetails.map(({ field, label, editable, props }) => (
                    editable ? (
                      <Grid item xs={12} sm={4} key={field}>
                        <TextField
                          fullWidth
                          margin="normal"
                          label={label}
                          error={!!errors[field]}
                          helperText={errors[field]}
                          name={field}
                          value={fields[field]}
                          onChange={this.onChange(field)}
                          data-testid={field}
                          {...props}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={4} key={field}>
                        <InputLabel shrink>{label}</InputLabel>
                        <ReadOnlyValue data-testid={field}>{profile[field]}</ReadOnlyValue>
                      </Grid>
                    )
                  ))
                }
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    autoSelect
                    value={fields.school}
                    options={lawSchools}
                    renderInput={(params) => <TextField fullWidth {...params} margin="normal" label="Law School *" />}
                    onChange={this.onChange('school')}
                    data-testid="school"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    autoSelect
                    value={fields.howHeard}
                    options={howHeard}
                    renderInput={(params) => <TextField fullWidth {...params} margin="normal" label="How Did You Hear About Us? *" />}
                    onChange={this.onChange('howHeard')}
                    data-testid="howHeard"
                  />
                </Grid>
              </Grid>

              {
                fields.howHeard === 'Other (Please Specify Below)' && (
                  <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        name="howHeardNotes"
                        data-testid="howHeardNotes"
                        label="How Did You Hear About Us?"
                        value={fields.howHeardNotes}
                        onChange={this.onChange('howHeardNotes')}
                      />
                    </Grid>
                  </Grid>
                )
              }

            </CardContent>
          </Card>
        </DataContainer>

        <Grid item xs={12}>
          {!setupStatus && profile.status === 'new' && (
            <Button
              disabled={!isFilled}
              onClick={this.beginStudentSetup}
              data-testid="startSetup"
            >
              Start Setup
            </Button>
          )}
        </Grid>
        {setupStatus && setupStatus !== 'complete' && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Setup Status" />

              <CardContent>
                <SimpleLinearProgress />
              </CardContent>
              <CardContent>{setupStatus}</CardContent>
            </Card>
          </Grid>
        )}
      </SetupScreen>
    );
  }
}

const mapStateToProps = ({ firebase, firestore, setupStatus }) => {
  return {
    studentId: firebase.auth.uid,
    profile: {
      ...firebase.profile,
      startDate: formatDateTime(firebase.profile.startDate),
    } as TStudent & { isLoaded: boolean },
    studentStatus: firebase.profile.status,
    studentInfo: firestore.data.studentInfo,
    setupStatus: setupStatus,
    studentOrganization: firestore.data.currentOrganization || null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startStudentSetup: () => dispatch(startStudentSetup()),
    updateStudent: (student: Partial<TStudent>, hideAlert = false) => {
      dispatch({
        type: 'SETUP_STATUS',
        payload: 'Updating student details...',
      });

      return dispatch(updateStudent(student, hideAlert));
    },
    finishStudentSetup: () => dispatch({ type: 'SETUP_STATUS', payload: null }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  connector,
  firestoreConnect((props: any) => {
    if (props.profile && props.profile.organization) {
      return [
        {
          collection: 'organizations',
          doc: props.profile.organization,
          storeAs: 'currentOrganization'
        }
      ]
    }

    return [];
  })
)(Dashboard);
