import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import AuthErrorTxt from '../../../elements/AuthErrorTxt';
import { RootState } from '../../../store/createReduxStore';
import { useLocation } from 'react-router';
import getUrlParams from '../../../utils/getUrlParams';
import { TextField } from '@material-ui/core';
import { resetPassword } from '../../../store/actions/authActions';
import LoadingModal from '../../../components/LoadingModal';

type TUrlParams = {
  apiKey: string,
  mode: string,
  oobCode: string,
  email: string,
}

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = getUrlParams<TUrlParams>(location.search);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [urlError, setUrlError] = useState('');

  const authError = useSelector((state: RootState) => state.auth.error);
  const status = useSelector((state: RootState) => state.auth.status);
  const auth = useSelector((state: RootState) => state.firebase.auth);
  const role = useSelector((state: RootState) => state.firebase.profile.role);

  useEffect(() => {
    const { email, oobCode, apiKey } = params;

    if (!email || !oobCode || !apiKey) {
      setUrlError('Invalid url. The password could not be reset.');
    } else {
      setUrlError('');
    }
  }, [params])

  const onChange = (name: string) => (e) => {
    const val = e.target.value;
    if (errors[name]) {
      setErrors({});
    }

    if (name === 'password') {
      setPassword(val);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(val);
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password.length < 6) {
      setErrors({
        ...errors,
        password: 'Password is too short'
      })
      return;
    }

    if (password !== confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: 'Passwords do not match'
      })

      return;
    }

    setErrors({});

    dispatch(resetPassword(params.oobCode, password, params.email));
  };

  if (auth.uid && role !== undefined)
    return (
      <Redirect
        to={{
          pathname: `/${role}`,
          state: { role: role },
        }}
      />
    );

  const error = urlError || authError;

  return (
    <form onSubmit={handleSubmit}>

      <LoadingModal open={status === 'loading'} />

      <FormControl margin="normal" required fullWidth>
        <TextField
          type="password"
          fullWidth
          label="Password"
          error={!!errors['password']}
          helperText={errors['password']}
          name="password"
          value={password}
          inputProps={{ maxLength: 255 }}
          onChange={onChange('password')}
        />
      </FormControl>

      <FormControl margin="normal" required fullWidth>
        <TextField
          type="password"
          fullWidth
          label="Confirm Password"
          error={!!errors['confirmPassword']}
          helperText={errors['confirmPassword']}
          name="confirmPassword"
          value={confirmPassword}
          inputProps={{ maxLength: 255 }}
          onChange={onChange('confirmPassword')}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <Button
          disabled={!!urlError}
          type="submit"
          variant="contained"
          fullWidth={true}
          color="primary"
          data-testid="changePassword"
        >
          Change Password
        </Button>

        <AuthErrorTxt data-testid="authErrorText">{error ? <p>{error}</p> : null}</AuthErrorTxt>
      </FormControl>

    </form>
  );
}

export default ResetPassword;
