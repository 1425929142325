import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CtaLanding from '../../../elements/CtaLanding';
import AuthErrorTxt from '../../../elements/AuthErrorTxt';
import { signUp } from '../../../store/actions/authActions';
import { useParams } from 'react-router';
import { useFirestoreConnect } from 'react-redux-firebase';
import LoadingModal from '../../../components/LoadingModal';

const SignUp = () => {
  useFirestoreConnect(['organizations']);
  const dispatch = useDispatch();
  const params = useParams();

  const auth = useSelector(state => state.firebase.auth);
  const error = useSelector(state => state.auth.error);
  const status = useSelector(state => state.auth.status);
  const role = useSelector(state => state.firebase.profile.role);
  const organizations = useSelector(state => state.firestore.ordered.organizations);

  const [organizationId, setOrganizationId] = useState('');
  const [fields, setFields] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    if (params.school && organizations) {
      const result = organizations.find(({ name }) => (
        name.toLowerCase().replace(/\s/g, '-') === params.school
      ));

      if (result) {
        setOrganizationId(result.id);
      }
    }
  }, [organizations, params.school]);

  const handleChange = e => {
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(signUp({
      ...fields,
      role: 'student',
      level: 'free',
      organization: organizationId,
    }));
  };

  if (auth.uid && role !== undefined)
    return (
      <Redirect
        to={{
          pathname: `/${role}`,
          state: { role: role },
        }}
      />
    );

  return (
    <form onSubmit={handleSubmit}>
      <LoadingModal open={status === 'loading'} />

      <Typography component='h1' variant='h5'>Sign Up</Typography>

      <FormControl margin='normal' required fullWidth>
        <InputLabel htmlFor='email'>Email Address</InputLabel>
        <Input
          id='email'
          name='email'
          autoComplete='email'
          autoFocus
          onChange={handleChange}
        />
      </FormControl>
      <FormControl margin='normal' required fullWidth>
        <InputLabel htmlFor='password'>Password</InputLabel>
        <Input
          name='password'
          type='password'
          id='password'
          autoComplete='current-password'
          onChange={handleChange}
        />
      </FormControl>
      <FormControl margin='normal' required fullWidth>
        <InputLabel htmlFor='password'>Password Check</InputLabel>
        <Input
          name='password2'
          type='password'
          id='password2'
          onChange={handleChange}
        />
      </FormControl>
      <FormControl margin='normal' required fullWidth>
        <InputLabel htmlFor='firstName'>First Name</InputLabel>
        <Input
          type='text'
          name='firstName'
          id='firstName'
          onChange={handleChange}
        />
      </FormControl>
      <FormControl margin='normal' required fullWidth>
        <InputLabel htmlFor='lastName'>Last Name</InputLabel>
        <Input
          type='text'
          name='lastName'
          id='lastName'
          onChange={handleChange}
        />
      </FormControl>

      <FormControl margin='normal' fullWidth>
        <>
          <Button type='submit' variant='contained' color='primary' fullWidth data-testid='signUp'>
            Sign Up
          </Button>
          <AuthErrorTxt>{error ? <p>{error}</p> : null}</AuthErrorTxt>

          <CtaLanding>
            Already have an account?{' '}
            <Link
              to={{
                pathname: '/',
              }}
            >
              Sign in.
            </Link>
          </CtaLanding>
        </>
      </FormControl>
    </form>
  );
};

export default SignUp;
