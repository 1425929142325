import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/createReduxStore';
import { useEffect } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import GenericTable from '../../../../components/GenericTable';
import { Checkbox, Grid } from '@material-ui/core';
import Button from '../../../../elements/Button';
import { FormControlLabel } from './StyledList';
import { useHistory } from 'react-router';
import { removeUser } from '../../../../store/actions/adminActions';
import Dialog from '../../../../components/Dialog';

const newCols = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'First',
    selector: 'firstName',
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Last',
    selector: 'lastName',
  },
  {
    id: 'state',
    align: 'left',
    disablePadding: false,
    label: 'Email',
    selector: 'email',
  },
];

type TProps = {};

const List = (props: TProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showInactives, setInactivesFlag] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const organization = useSelector((state: RootState) => state.firestore.data.currentOrganization);
  const users = useSelector((state: RootState) => state.firestore.ordered.organizationUsers);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const status = useSelector((state: RootState) => state.userStatus.status);

  const isOrganizationUser = organization && organization.id === profile.token.claims.organization;

  const userMenuItems = ['view'];
  const toConnect = organization ? [
    { collection: 'users', where: [['organization', '==', organization.id], ['role', '==', 'admin']], storeAs: 'organizationUsers' },
  ] : [];

  //@ts-ignore
  useFirestoreConnect(toConnect)

  useEffect(() => {
    if (users) {
      setFiltered(users.filter((item) => showInactives || item.isActive))
    }
  }, [users, showInactives])

  const onMenuAction = (e: any, id: string, action: string) => {
    if (action === 'view') {
      history.push(`/admin/organizations/view/${organization.id}/user/view/${id}`);
    } else if (action === 'delete') {
      setUserToDelete(id);
    }
  }

  const onAddUser = () => {
    history.push(`/admin/organizations/${organization.id}/user/new`);
  }

  const onCloseDialog = () => {
    setUserToDelete(null);
  }

  const onConfirmDialog = () => {
    dispatch(removeUser(userToDelete));
    setUserToDelete(null);
  }

  if (!isOrganizationUser) {
    userMenuItems.push('delete');
  }

  return (
    <Grid container spacing={3}>
      <Dialog
        type={'confirm'}
        title={'Confirm'}
        content={'Are You sure You want to delete the user?'}
        open={userToDelete || status === 'loading'}
        isLoading={status === 'loading'}
        onCancel={onCloseDialog}
        onConfirm={onConfirmDialog}
        buttonLabels={['CANCEL', 'DELETE']}
      />

      <Grid item container xs={12} justify={'space-between'}>
        <Grid item>
          <FormControlLabel
            checked={showInactives}
            label="View inactive users"
            onChange={() => setInactivesFlag(!showInactives)}
            control={<Checkbox value={'showInactives'} />}
          />
        </Grid>

        {
          !isOrganizationUser && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                aria-label="Search"
                onClick={onAddUser}
              >
                + ADD USER
              </Button>
            </Grid>
          )
        }

      </Grid>

      <Grid item xs={12}>
        <GenericTable
          menuItems={userMenuItems}
          colHeaders={newCols}
          data={filtered}
          onMenuAction={onMenuAction}
        />
      </Grid>
    </Grid>
  );
}

export default List;
