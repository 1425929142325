import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const Wrapper = styled(({ overflow, ...props }) => <div {...props} />)`
  background-color: ${({ overflow }) => overflow ? 'rgba(255,255,255,.75)' : 'unset'};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000
`;

const Loader = styled(({ position, ...props }) => <div {...props} />)`
  animation: ${rotationBuilder(10)} 1.5s linear infinite;
  clip: rect(0, 80px, 80px, 40px);
  height: 80px;
  width: 80px;
  position: ${({ position }) => position || 'fixed'};
  z-index: 1000;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  &:after {
    animation: ${rotationBuilder2(10)} 1.5s ease-in-out infinite;
    clip: rect(0, 80px, 80px, 40px);
    content: '';
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: absolute;
  }
`;

function rotationBuilder(degree) {
  const rotation = keyframes`
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(220deg)
    }
  `;
  return rotation;
}

function rotationBuilder2(degree) {
  const rotation2 = keyframes`
    0% {
      box-shadow: inset #5c67e5 0 0 0 17px;
      transform: rotate(-140deg);
    }
    50% {
      box-shadow: inset #5c67e5 0 0 0 2px;
    }
    100% {
      box-shadow: inset #5c67e5 0 0 0 17px;
      transform: rotate(140deg);
    }
  `;
  return rotation2;
}

type TProps = {
  overflow?: boolean,
  position?: 'static' | 'absolute' | 'fixed'
}

class Loading extends Component<TProps> {

  render() {
    const { overflow, position, ...props } = this.props;

    return overflow ? (
      <Wrapper {...props} overflow>
        <Loader position={position} />
      </Wrapper>
    ) : (
      <Loader position={position} />
    )
  }
}

export default Loading;
