export const LANDING = '/';
export const ADMIN = '/admin';
export const STUDENT = '/student';
export const SETUP = '/setup';
export const UPGRADE = '/upgrade';

export const AdminRoutes = {
  DASHBOARD: '/admin',
  USERS: '/admin/users',
  STUDENTS: '/admin/students',
  VIEW_STUDENT: '/admin/students/view',
  STUDY: '/admin/study',
  FREE: '/admin/free',
  TEST: '/admin/test',
  ORGANIZATIONS: '/admin/organizations',
  ORGANIZATION_VIEW: '/admin/organizations/view',
  PROFILE: '/admin/profile',
};

export const OrganizationAdminRoutes = {
  STUDENTS: '/admin/students',
  VIEW_STUDENT: '/admin/students/view',
  ACCOUNT_DETAILS: '/admin/organizations',
  PROFILE: '/admin/profile',
};
