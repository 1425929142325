export const test = [
  {
    label: 'Practice Test 01',
    value: 'test-ope01',
  },
  {
    label: 'Practice Test 02',
    value: 'test-ope02',
  },
  {
    label: 'Practice Test 03',
    value: 'test-ope03',
  },
  {
    label: 'Practice Test 04',
    value: 'test-ope04',
  },
  {
    label: 'Final Test Part 1',
    value: 'test-final01',
  },
  {
    label: 'Final Test Part 2',
    value: 'test-final02',
  },
];

export const study = [
  {
    label: 'Criminal Law and Procedure',
    value: 'study-cri',
  },
  {
    label: 'Civil Procedure',
    value: 'study-civ',
  },
  {
    label: 'Evidence',
    value: 'study-evd',
  },
  {
    label: 'Torts',
    value: 'study-trt',
  },
  {
    label: 'Constitutional Law',
    value: 'study-con',
  },
  {
    label: 'Contracts',
    value: 'study-ksl',
  },
  {
    label: 'Real Property',
    value: 'study-rpl',
  },
];
export const free = [
  {
    label: 'Criminal Law and Procedure',
    value: 'study-cri',
  },
  {
    label: 'Civil Procedure',
    value: 'study-civ',
  },
  {
    label: 'Evidence',
    value: 'study-evd',
  },
  {
    label: 'Torts',
    value: 'study-trt',
  },
  {
    label: 'Constitutional Law',
    value: 'study-con',
  },
  {
    label: 'Contracts',
    value: 'study-ksl',
  },
  {
    label: 'Real Property',
    value: 'study-rpl',
  },
  {
    label: 'Practice Test 01',
    value: 'test-ope01',
  },
  {
    label: 'Practice Test 02',
    value: 'test-ope02',
  },
  {
    label: 'Practice Test 03',
    value: 'test-ope03',
  },
  {
    label: 'Practice Test 04',
    value: 'test-ope04',
  },
  {
    label: 'Final Test Part 1',
    value: 'test-final01',
  },
  {
    label: 'Final Test Part 2',
    value: 'test-final02',
  },
];
export const subjectCodes = {
  CRI: {
    label: 'Criminal Law and Procedure',
    qbank: 'study-cri',
    num: 0,
  },
  CIV: {
    label: 'Civil Procedure',
    qbank: 'study-civ',
    num: 0,
  },
  EVD: {
    label: 'Evidence',
    qbank: 'study-evd',
    num: 0,
  },
  TRT: {
    label: 'Torts',
    qbank: 'study-trt',
    num: 0,
  },
  CON: {
    label: 'Constitutional Law',
    qbank: 'study-con',
    num: 0,
  },
  KSL: {
    label: 'Contracts',
    qbank: 'study-ksl',
    num: 0,
  },
  RPL: {
    label: 'Real Property',
    qbank: 'study-rpl',
    num: 0,
  },
};
