import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { signOut } from '../../store/actions/authActions';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router';

const menuItems = [
  {
    name: 'profile',
    label: 'PROFILE',
  },
  {
    name: 'users',
    label: 'USERS',
    role: 'admin',
    organizations: []
  },
];

const UserMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const initials = useSelector((state) => state.firebase.profile.initials);
  const role = useSelector((state) => state.firebase.profile.role);
  const organization = useSelector((state) => state.firebase.profile.organization);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickItem = (name) => () => {
    history.push(`/${role}/${name}`);
    setAnchorEl(null);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = () => {
    dispatch(signOut());
  }

  const open = !!anchorEl;

  return (
    <div>
      <IconButton
        aria-owns={open ? 'menu-appbar' : null}
        aria-haspopup='true'
        onClick={onOpen}
        color='inherit'
        data-testid='navbarAvatar'
      >
        <Avatar>{initials}</Avatar>
      </IconButton>

      <Menu
        id='menu-appbar'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
      >
        {menuItems.map((item) => {
          const hasCorrectRole = !item.role || item.role === role;
          const hasCorrectOrganization = !organization || !item.organizations;

          return hasCorrectRole && hasCorrectOrganization && (
            <MenuItem key={item.name} onClick={onClickItem(item.name)}>{item.label}</MenuItem>
          )
        })}
        <Divider />
        <MenuItem onClick={onSignOut} data-testid='logOut'>LOG OUT</MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
