import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
import { FormControl } from '../../../../containers/Form';
import { createNewStudent, resetStudentView } from '../../../../store/actions/studentActions';
import LoadingModal from '../../../../components/LoadingModal';
import { get } from 'lodash';
import BackButton from '../../../../elements/BackButton';

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogStatus: 'ready',
      dialogType: 'new',
      isDialogOpen: false,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      role: 'student',
      status: '',
      accountType: '',
      studentType: 'manual',
      accountLevel: '',
    };
    this.goBackScreen = this.goBackScreen.bind(this);
  }

  componentDidMount() {
    this.resetFormFields();
  }

  resetFormFields() {
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      password: Math.random()
        .toString(36)
        .slice(-8),
      accountType: '',
      accountLevel: '',
    });
  }

  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/students`);
    e.preventDefault();
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSelectChange = event => {
    this.setState({
      accountLevel: event.target.value,
      accountType: event.target.value === 'paid' ? 'pro' : 'trial',
    });
  };

  handleDialogCancel = type => {
    const { history, resetStudentView } = this.props;
    if (type === 'create-student-error') {
      resetStudentView();
    } else {
      resetStudentView();
      history.push(`/admin/students`);
    }
  };

  handleDialogConfirm = type => {
    const { resetStudentView } = this.props;

    resetStudentView();
    this.resetFormFields();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isDialogOpen, dialogStatus, dialogType, ...sobj } = this.state;
    const organization = get(this.props.profile, 'token.claims.organization');

    if (organization) {
      sobj.organization = organization;
    }

    this.props.createNewStudent(sobj);
  };

  render() {
    const {
      email,
      password,
      firstName,
      lastName,
      accountLevel,
    } = this.state;
    const { status } = this.props;
    const inValid = firstName === '' || lastName === '' || email === '' || accountLevel === '';

    return (
      <>
        <LoadingModal open={status === 'loading'} />

        <Screen>
          <MainScreen
            title='New Student'
            leftSideItem={<BackButton onClick={this.goBackScreen} />}
            spacing={3}
          >
            <Grid item xs={12}>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <FormControl margin='normal' required>
                          <InputLabel htmlFor='firstName'>
                            First Name
                          </InputLabel>
                          <Input
                            type='text'
                            name='firstName'
                            value={firstName}
                            id='firstName'
                            onChange={this.handleChange}
                            autoFocus
                          />
                        </FormControl>
                        <FormControl margin='normal' required>
                          <InputLabel htmlFor='lastName'>Last Name</InputLabel>
                          <Input
                            type='text'
                            name='lastName'
                            value={lastName}
                            id='lastName'
                            onChange={this.handleChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <FormControl
                          margin='normal'
                          required
                        >
                          <InputLabel htmlFor='email'>Email Address</InputLabel>
                          <Input
                            id='email'
                            name='email'
                            value={email}
                            autoComplete='email'
                            onChange={this.handleChange}
                          />
                        </FormControl>
                        <FormControl margin='normal' required>
                          <InputLabel htmlFor='password'>Password</InputLabel>
                          <Input
                            name='password'
                            type='text'
                            value={password}
                            id='password'
                            readOnly
                          />
                        </FormControl>

                        {
                          this.props.organization && (
                            <FormControl margin='normal' required>
                              <InputLabel htmlFor='password'>Organization</InputLabel>
                              <Input
                                type='text'
                                value={this.props.organization.name}
                                id='password'
                                readOnly
                              />
                            </FormControl>
                          )
                        }

                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs>
                        <FormControl required>
                          <InputLabel htmlFor='accountLevel-native-simple'>
                            Account Type
                          </InputLabel>
                          <Select
                            native
                            value={accountLevel}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: 'accountLevel',
                              id: 'accountLevel-native-simple',
                            }}
                          >
                            <option value='' />
                            <option value='free'>Free</option>
                            <option value='paid'>Paid</option>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          fullWidth
                          disabled={inValid}
                        >
                          Create Student
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </MainScreen>
        </Screen>
      </>
    );
  }
}

New.propTypes = {
  status: PropTypes.string,
  error: PropTypes.string,
  organization: PropTypes.object,
};

const mapStateToProps = state => ({
  status: state.userStatus.status,
  error: state.userStatus.error,
  profile: state.firebase.profile,
});

const mapDispatchToProps = {
  createNewStudent,
  resetStudentView,
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
