import React, { Component } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import DateTime from '../../../../elements/DateTime';
import { MainScreen } from '../../../../containers/Main';
import BySubjectBar from '../../../../charts/BySubjectBar';
import PromoSection from '../../../../promos/PromoSection';
import subjectTopics from '../../../../_CONS/subjectTopics';
import ResultsPanel from '../../../../components/ResultsPanel';
import StatsRowCardCalc from '../../../../components/StatsRowCardCalc';
import { clearGuide, openGuide } from '../../../../store/actions/guideActions';
import { RouteComponentProps } from 'react-router';
import { Card, CardContent, CardHeader } from '../../../../containers/Card';
import { TStatus } from '../../../../store/types';
import LoadingModal from '../../../../components/LoadingModal';
import Button from '../../../../elements/Button';
import { HeaderInfo, HeaderTitle } from '../../../../containers/Main/MainScreen/StyledMainScreen';
import styled from 'styled-components/macro';
import theme from '../../../../styles/theme';

type TProps = PropsFromRedux & RouteComponentProps;

const NavButton = styled(Button)`
  margin-right: ${theme.spacing(3)}px;
`;

class ReviewDash extends Component<TProps> {
  constructor(props) {
    super(props);
    this.loadCurrentStudyGuideItem = this.loadCurrentStudyGuideItem.bind(this);
    this.loadCurrentTestGuideItem = this.loadCurrentTestGuideItem.bind(this);
  }

  componentDidMount() {
    this.props.clearGuide();
  }

  createChartData() {
    const bySubjectCode = []

    const mymp = bySubjectCode.map(function (item) {
      var innerarray = item.values;
      const total = innerarray.length;

      var correct = innerarray.filter(q => q.isCorrect === true).length;
      var wrong = total - correct;

      const subjectObject = subjectTopics[item.key];
      const subjectLabel = subjectObject.label;
      return {
        subject: item.key,
        subjectLabel,
        total: total,
        correct: correct,
        wrong: wrong,
      };
    });
    return mymp;
  }


  calcOverall() {
    const { student } = this.props;
    return (student.allCorrect / student.allTotal) * 100;
  }

  loadCurrentStudyGuideItem(id) {
    const obj = {
      guideType: 'studyguides',
      guideId: id,
      status: 'loading' as TStatus,
    };

    this.props.openGuide(obj).then(() => {
      this.props.history.push(
        `${this.props.location.pathname}/studyguides/overview`
      );
    })
  }

  loadCurrentTestGuideItem(id) {
    const obj = {
      guideType: 'testguides',
      guideId: id,
      status: 'loading' as TStatus,
    };

    this.props.openGuide(obj).then(() => {
      this.props.history.push(
        `${this.props.location.pathname}/testguides/overview`
      );
    })
  }

  totalStatObject() {
    const { student } = this.props;
    const { total, correct } = student.all;
    const rawoverall = (correct / total) * 100;
    const overall = isNaN(rawoverall) ? '0' : rawoverall.toFixed(1);

    const statObj = {
      total: student.all.total,
      correct: student.all.correct,
      wrong: student.all.wrong,
      overall,
    };
    return statObj;
  }

  openStudyAidInfo = () => {
    this.props.history.push('/student/review/study-aid-info');
  }

  render() {
    const {
      student,
      studyGuides,
      practiceTestGuides,
      finalTestGuides,
      sgQs,
      finalTgQs,
      practiceTgQs,
      allQs,
      allQsCorrect,
    } = this.props;

    return (
      <>
        <LoadingModal open={this.props.status === 'loading'} />

        <MainScreen withoutHeader>
          <Grid container item>
            <Grid item>
              <NavButton disabled>
                Review Tests
              </NavButton>
            </Grid>

            <Grid item>
              <NavButton onClick={this.openStudyAidInfo}>
                Study Aid Info
              </NavButton>
            </Grid>
          </Grid>

          <Grid
            container
            item
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <HeaderTitle>Review</HeaderTitle>
            </Grid>

            <Grid item xs={6}>
              <HeaderInfo>
                <DateTime>{new Date()}</DateTime>
              </HeaderInfo>
            </Grid>
          </Grid>

          {student && (
            <Grid item xs>
              <StatsRowCardCalc
                allTotal={allQs.length}
                allCorrect={allQsCorrect.length}
              />
            </Grid>
          )}
          {allQs.length > 0 && (
            <Hidden xsDown>
              <Grid container item xs={12}>
                <Card>
                  <CardHeader title="Performance by Subject" />
                  <CardContent>
                    <BySubjectBar
                      data={allQs}
                      width="100%"
                      height={300}
                      minWidth={800}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Hidden>
          )}

          <Grid container item xs={12}>
            {
              // @ts-ignore
              <PromoSection />
            }
          </Grid>

          {
            studyGuides && (
              <Grid container item xs={12}>
                <ResultsPanel
                  appSection="review"
                  section="Study Aids"
                  loadCurrentItem={this.loadCurrentStudyGuideItem}
                  items={studyGuides}
                  questions={sgQs}
                />
              </Grid>
            )
          }

          {
            practiceTestGuides && (
              <Grid container item xs={12}>
                <ResultsPanel
                  appSection="review"
                  loadCurrentItem={this.loadCurrentTestGuideItem}
                  section="Practice Tests"
                  items={practiceTestGuides}
                  questions={practiceTgQs}
                />
              </Grid>
            )
          }

          {
            finalTestGuides && (
              <Grid container item xs={12}>
                <ResultsPanel
                  appSection="review"
                  loadCurrentItem={this.loadCurrentTestGuideItem}
                  section="Final Tests"
                  items={finalTestGuides}
                  questions={finalTgQs}
                />
              </Grid>
            )
          }
        </MainScreen>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    studyGuides,
    testGuides,
    sgQuestions,
    tgQuestions,
  } = state.firestore.ordered;

  const completedStudyGuides = studyGuides ? studyGuides.filter(({ completed }) => completed) : [];
  const practiceTestGuides = testGuides ? testGuides.filter(({ completed, testType }) => completed && testType === 'ope') : [];
  const finalTestGuides = testGuides ? testGuides.filter(({ completed, testType }) => completed && testType === 'final') : [];

  const sgQs = sgQuestions ? sgQuestions.filter((q => q.answered)) : [];
  const practiceTgQs = tgQuestions ? tgQuestions.filter(({ qbank }) => qbank.indexOf('-ope') !== -1) : [];
  const finalTgQs = tgQuestions ? tgQuestions.filter(({ qbank }) => qbank.indexOf('-final') !== -1) : [];

  const sgQsCorrect = sgQuestions ? sgQuestions.filter(q => q.isCorrect) : [];
  const tgQsCorrect = tgQuestions ? tgQuestions.filter(q => q.isCorrect) : [];

  const allQs = [...sgQs, ...practiceTgQs, ...finalTgQs];
  const allQsCorrect = allQs.length > 0 ? allQs.filter(q => q.isCorrect) : [];

  return {
    studentId: state.auth.uid,
    student: state.firestore.data.student,
    practiceTestGuides,
    finalTestGuides,
    studyGuides: completedStudyGuides,
    sgQs,
    practiceTgQs,
    finalTgQs,
    sgQsCorrect,
    tgQsCorrect,
    allQs,
    allQsCorrect,
    overall: allQsCorrect.length,
    status: state.guideStatus.status,
    error: state.guideStatus.error,
  };
};

const mapDispatchToProps = {
  clearGuide,
  openGuide,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default compose(withRouter, connector)(ReviewDash);
