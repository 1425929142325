import React from 'react';
import { BackupIconBackground, Hidden, Upload, UploadIcon, Wrapper } from './StyledUploader';

type TUploader = {
  name: string,
  accept?: string,
  onChange: (e: any) => void,
}

const Uploader: React.FC<TUploader> = ({
  name,
  accept = ".png, .jpg, .jpeg",
  ...props
}) => {
  const onChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Wrapper>
      {props.children}

      <Upload htmlFor={name}>
        <UploadIcon fontSize="inherit" />
        <BackupIconBackground />
      </Upload>

      <Hidden>
        <input onChange={onChange} type="file" id={name} accept={accept} />
      </Hidden>
    </Wrapper>
  )
}

export default Uploader;
