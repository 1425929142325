import { TextField as MuiTextField, Select as MuiSelect } from '@material-ui/core';
import styled from 'styled-components/macro';

export const TextField = styled(MuiTextField)`
  min-width: 15rem;
`;

export const Select = styled(MuiSelect)`
  min-width: 15rem;
`;
