import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../../../../elements/Button';
import styled from 'styled-components/macro';
import { fontWeight } from '../../../../styles/style';

type TProps = {
  orderNumber: string,
  onClick: Function
}

const Title = styled(Typography)`
  font-weight: ${fontWeight.semiBold}
`;

const Container = styled(Grid)`
  text-align: center;
  width: 80%
`

const ThankYouMessage: React.FC<TProps> = (props) => {
  return (
    <Grid container justify={'center'} >
      <Container item>
        <Title variant={'h5'} color={'textSecondary'} gutterBottom>Thank You!</Title>
        <Typography variant={'subtitle1'} color={'textSecondary'} gutterBottom>You have been successfully upgraded to a Qbank Pro Account!</Typography>
        <Typography variant={'body1'} color={'textSecondary'} gutterBottom paragraph>For your records, your order # is: {props.orderNumber}</Typography>

        <Button
          aria-label={'setup pro account'}
          color={'primary'}
          onClick={props.onClick}
        >
          Setup My Pro Account
        </Button>
      </Container>
    </Grid>
  )
}

export default ThankYouMessage;
