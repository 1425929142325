import React, { Component } from 'react';
import { NavLink, Switch, Route, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { firestoreConnect } from 'react-redux-firebase';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import UserMenu from '../../components/UserMenu';
import Logo from '../../assets/logos/JD-Advising_Logo.svg';
import Dashboard from './Dashboard';
import Study from './Study';
import Review from './Review';
import Test from './Test';
import Profile from './Profile';
import { signOut } from '../../store/actions/authActions';
import {
  Nav,
  NavBtn,
  NavRight,
  NavMobile,
  NavBtnLink,
  ToolbarWide,
  MobileLeft,
  LogoImage,
  Container,
} from './StyledStudentPathway';
import { Redirect } from 'react-router';
import Upgrade from './Upgrade';
import { withErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import StudentFAQ from '../../screens/StudentFAQ';

class StudentPathway extends Component {
  state = {
    mobileOpen: false,
    auth: true,
    anchorEl: null,
    dialogOpen: false,
    scroll: 'paper',
  };

  handleSignOut = () => {
    this.props.signOut();
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { auth } = this.state;
    const { match } = this.props;

    return (
      <>
        <AppBar color='default' position={'relative'}>
          <Hidden mdUp implementation='css'>
            <ToolbarWide variant='regular' disableGutters={true}>
              <MobileLeft>
                <IconButton
                  color='inherit'
                  aria-label='Open drawer'
                  onClick={this.handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <NavLink exact to={`${match.url}`}>
                  <LogoImage src={Logo} alt='logo' />
                </NavLink>
              </MobileLeft>
            </ToolbarWide>
          </Hidden>
          <Hidden smDown implementation='css'>
            <ToolbarWide variant='regular'>
              <NavLink exact to={`${match.url}`}>
                <LogoImage src={Logo} alt='logo' />
              </NavLink>

              <Nav>
                <NavBtn exact to={`${match.url}`}>
                  DASHBOARD
                </NavBtn>
                <NavBtn to={`${match.url}/study`}>STUDY</NavBtn>
                <NavBtn to={`${match.url}/test`}>TEST</NavBtn>
                <NavBtn to={`${match.url}/review`}>REVIEW</NavBtn>
              </Nav>
              <NavRight>
                <Link to='/student/faq'>Have questions about Qbank?</Link>
                {auth && <UserMenu />}
              </NavRight>
            </ToolbarWide>
          </Hidden>
        </AppBar>
        <Hidden mdUp implementation='css'>
          <Drawer
            container={this.props.container}
            variant='temporary'
            anchor={'left'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
          >
            <NavMobile>
              <NavBtnLink
                onClick={this.handleDrawerToggle}
                exact
                to={`${match.url}`}
              >
                DASHBOARD
              </NavBtnLink>
              <NavBtnLink
                onClick={this.handleDrawerToggle}
                to={`${match.url}/study`}
              >
                STUDY
              </NavBtnLink>
              <NavBtnLink
                onClick={this.handleDrawerToggle}
                to={`${match.url}/test`}
              >
                TEST
              </NavBtnLink>
              <NavBtnLink
                onClick={this.handleDrawerToggle}
                to={`${match.url}/review`}
              >
                REVIEW
              </NavBtnLink>
            </NavMobile>
            <Divider />
            <NavMobile>
              <NavBtnLink
                onClick={this.handleDrawerToggle}
                to={`${match.url}/profile`}
              >
                Profile
              </NavBtnLink>
              <NavBtnLink onClick={this.handleSignOut} to={`/`} exact>
                Sign Out
              </NavBtnLink>
            </NavMobile>
          </Drawer>
        </Hidden>

        <Container>
          <Switch>
            <Route path={`${match.url}/upgrade`} component={withErrorBoundary(Upgrade)} />
            <Route path={`${match.url}/study`} component={withErrorBoundary(Study)} />
            <Route path={`${match.url}/test`} component={Test} />
            <Route path={`${match.url}/review`} component={Review} />
            <Route path={`${match.url}/profile`} component={Profile} />
            <Route path={`${match.url}/faq`} component={StudentFAQ} />

            <Route exact path={`${match.url}`} component={Dashboard} />
            <Redirect to={`${match.url}`} />
          </Switch>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    studentId: state.firebase.auth.uid,
    levelQbank: state.firebase.profile.studentBank,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    return [
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        storeAs: 'student',
      },
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          { collection: 'studyguides', orderBy: ['createdDate', 'asc'] },
        ],
        storeAs: 'studyGuides',
      },
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          {
            collection: 'sgquestions',
            where: [['answered', '==', true]],
          },
        ],
        storeAs: 'sgQuestions',
      },
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          { collection: 'testguides', orderBy: ['guideNum', 'asc'] },
        ],
        storeAs: 'testGuides',
      },
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          {
            collection: 'tgquestions',
            where: [['answered', '==', true]],
          },
        ],
        storeAs: 'tgQuestions',
      },
    ];
  })
)(StudentPathway);
