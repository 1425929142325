import React from 'react';
import format from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import { CardContent } from '../../containers/Card';

const Billing = props => {
  const { user } = props;
  const { transactionData } = user;
  return (
    <CardContent>
      <Typography gutterBottom variant='h6'>Payment Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label='Id'
              value={transactionData ? transactionData.transId : 'bundle'}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label='Date'
              value={format(user.upgradedDate.seconds * 1000, 'MM/DD/YY - HH:MMA')}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label='Payment Type'
              value={'Credit Card'}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              label='Amount'
              value={'299.99'}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </CardContent>
  );
};

Billing.propTypes = {};

export default Billing;
