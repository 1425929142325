import {OrganizationActions} from "../actions/organizationsActions";
import {TStatus} from "../types";

export type TOrganizationsState = {
  status: TStatus,
  error: string,
};

const {
  CREATE,
  UPDATE,
  DELETE,
  ADD_OFFER,
  RESET_STATUS,
  UPDATE_OFFER,
  LOCK_TESTS
} = OrganizationActions;

const initState: TOrganizationsState = {
  error: '',
  status: '',
};

const organizationReducer = (state: TOrganizationsState = initState, action: any): TOrganizationsState => {
  const {payload, type} = action;
  
  switch (type) {
    case UPDATE.REQUESTED:
    case DELETE.REQUESTED:
    case ADD_OFFER.REQUESTED:
    case UPDATE_OFFER.REQUESTED:
    case LOCK_TESTS.REQUESTED:
    case CREATE.REQUESTED:
      return {
        ...state,
        status: 'loading',
      }
    
    case ADD_OFFER.SUCCESS:
    case UPDATE_OFFER.SUCCESS:
    case UPDATE.SUCCESS:
    case DELETE.SUCCESS:
    case LOCK_TESTS.SUCCESS:
    case CREATE.SUCCESS:
      return {
        ...state,
        status: 'success',
      }
    
    case UPDATE.FAILURE:
    case ADD_OFFER.FAILURE:
    case UPDATE_OFFER.FAILURE:
    case DELETE.FAILURE:
    case LOCK_TESTS.FAILURE:
    case CREATE.FAILURE:
      return {
        ...state,
        status: 'failure',
        error: payload
      }
    
    case RESET_STATUS:
      return {
        ...state,
        status: '',
        error: '',
      }
    
    default:
      return state;
  }
}

export default organizationReducer;
