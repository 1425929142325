import React from 'react';
import { MainScreen } from '../../../../../containers/Main';
import { Card, CardContent } from '../../../../../containers/Card';
import { useHistory, useParams } from 'react-router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/createReduxStore';
import { TextField, Select } from './StyledNew';
import { validate, validateAccountType, validateEmail, validateName } from '../../../../../utils/validator';
import { createNewStudent } from '../../../../../store/actions/studentActions';
import { MenuItem, Grid, Button, } from '@material-ui/core';
import { useFirestoreConnect } from 'react-redux-firebase';
import { TOrganization } from '../../../../../../types';
import LoadingModal from '../../../../../components/LoadingModal';
import BackButton from '../../../../../elements/BackButton';

type TFields = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  jobTitle: string,
  phoneNumber: string,
  accountType: string,
}

type TUrlParams = {
  id: string,
}

type TNewOrganizationUser = {}

const NewOrganizationUser: React.FC<TNewOrganizationUser> = (props) => {
  const history = useHistory();
  const params: TUrlParams = useParams();
  const dispatch = useDispatch();
  useFirestoreConnect([
    { collection: 'organizations', doc: params.id, storeAs: 'currentOrganization' },
  ]);

  const organization: TOrganization | null = useSelector((state: RootState) => state.firestore.data.currentOrganization);
  const status = useSelector((state: RootState) => state.organizationsReducer.status);

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: Math.random().toString(36).slice(-8),
    accountType: '',
  });

  const [errors, setErrors] = useState<Partial<TFields>>({});

  const goBack = () => {
    history.push(`/admin/organizations/view/${params.id}`);
  }

  const validateFields = () => {
    return validate(fields, {
      firstName: [validateName],
      lastName: [validateName],
      email: [validateEmail],
      accountType: [validateAccountType],
    });
  }

  const onChange = (field: string) => (e: any) => {
    let val = e.target.value;

    setFields({
      ...fields,
      [field]: val
    })
  };

  const onCreate = (e: any) => {
    const validationResult = validateFields();

    if (Object.keys(validationResult).length) {
      setErrors(validationResult);
    } else {
      setErrors({});

      dispatch(createNewStudent({
        ...fields,
        accountLevel: fields.accountType === 'pro' ? 'paid' : 'free',
        organization: params.id
      }));
    }
  };

  const onReset = (e: any) => {
    setErrors({});
    setFields({
      firstName: '',
      lastName: '',
      email: '',
      password: Math.random().toString(36).slice(-8),
      accountType: '',
    })
  };

  return (
    <MainScreen
      title="New Student"
      leftSideItem={<BackButton onClick={goBack} />}
      spacing={3}
    >
      <LoadingModal open={status === 'loading'} />

      <Grid item xs={12}>
        <Card>
          <form onSubmit={onCreate}>
            <CardContent>
              <Grid container spacing={3} >
                <Grid item container spacing={3} >
                  <Grid item>
                    <TextField
                      label="First Name *"
                      value={fields.firstName}
                      error={!!errors.firstName}
                      helperText={errors.firstName || ' '}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange('firstName')}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      label="Last Name *"
                      value={fields.lastName}
                      error={!!errors.lastName}
                      helperText={errors.lastName || ' '}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange('lastName')}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={3}>
                  <Grid item>
                    <TextField
                      label="Email *"
                      value={fields.email}
                      error={!!errors.email}
                      helperText={errors.email || ' '}
                      inputProps={{ maxLength: 255 }}
                      onChange={onChange('email')}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      label="Password"
                      value={fields.password}
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={3} alignItems={'flex-end'}>
                  <Grid item>
                    <Select
                      fullWidth
                      error={!!errors.accountType}
                      displayEmpty
                      value={fields.accountType}
                      onChange={onChange('accountType')}
                      inputProps={{ id: 'accountType', }}
                    >
                      <MenuItem disabled value=''>Select account type</MenuItem>
                      <MenuItem value='trial'>Free</MenuItem>
                      <MenuItem value='pro'>Paid</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled
                      label="Organization"
                      value={organization ? organization.name : ''}
                    />
                  </Grid>
                </Grid>

              </Grid>

              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreate}
                  >
                    Create
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </MainScreen >
  )
}

export default NewOrganizationUser;
