import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../store/api/firebaseAdmin';

type TResponse<T> = {
  isLoading: boolean,
  error: string,
  data: T | null,
}

function useOrganization<T>(id: string): TResponse<T> {
  const [state, setState] = useState({
    isLoading: true,
    error: '',
    data: null,
  })

  useEffect(() => {
    if (id) {
      setState({ ...state, isLoading: true, })

      firebase.firestore().collection('organizations').doc(id).get()
        .then((doc) => {
          if (doc.exists) {
            setState({
              error: '',
              isLoading: false,
              data: doc.data(),
            })
          } else {
            setState({
              error: 'The Organization doesn\'t exist.',
              isLoading: false,
              data: null,
            })
          }
        })
        .catch((e) => {
          setState({
            error: e.message,
            isLoading: false,
            data: null,
          })
        });
    } else {
      setState({
        error: '',
        isLoading: false,
        data: null,
      })
    }
  }, [id]);

  return state;
};

export default useOrganization;