import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components/macro';
import NavAppBar from '../../components/NavAppBar';
import NavBtn from '../../elements/NavBtn';
import Dashboard from './Dashboard';
import Students from './Students';
import Study from './Study';
import Test from './Test';
import Free from './Free';
import Profile from './Profile';
import Users from './Users';
import Preview from './Preview';
import EditQuestion from './EditQuestion';
import NewQuestion from './NewQuestion';
import {Redirect} from 'react-router';
import StudentFAQ from '../../screens/StudentFAQ';
import Organizations from './Organizations';
import {get} from 'lodash';
import {useFirestoreConnect} from 'react-redux-firebase';
import {AdminRoutes, OrganizationAdminRoutes} from '../../_CONS/routes';

const Container = styled.div`
  max-height: 100vh;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
`;

const AdminPathway = props => {
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const organization = useSelector(state => state.firestore.data.currentOrganization);
  const userOrganization = get(profile, 'token.claims.organization');

  const {match} = props;

  const toConnect = userOrganization ? [
    {collection: 'organizations', doc: userOrganization, storeAs: 'currentOrganization'},
  ] : [];

  useFirestoreConnect(toConnect);

  const isOrganizationUser = organization && organization.id === userOrganization;
  const isOrganizationAdmin = organization && organization.primaryContact === profile.uid;

  return (
    <>
      <NavAppBar auth={auth}>
        {
          !!userOrganization ? (
            <>
              <NavBtn to={OrganizationAdminRoutes.STUDENTS}>STUDENTS</NavBtn>
              {
                isOrganizationAdmin && (
                  <NavBtn to={`${OrganizationAdminRoutes.ACCOUNT_DETAILS}/view/${userOrganization}`}>ACCOUNT DETAILS</NavBtn>
                )
              }
            </>
          ) : (
            <>
              <NavBtn exact to={AdminRoutes.DASHBOARD}>DASHBOARD</NavBtn>
              <NavBtn to={AdminRoutes.STUDENTS}>STUDENTS</NavBtn>
              <NavBtn to={AdminRoutes.STUDY}>STUDY</NavBtn>
              <NavBtn to={AdminRoutes.TEST}>TEST</NavBtn>
              <NavBtn to={AdminRoutes.FREE}>FREE</NavBtn>
              <NavBtn to={AdminRoutes.ORGANIZATIONS}>ORGANIZATIONS</NavBtn>
            </>
          )
        }
      </NavAppBar>

      <Container>
        {
          !!userOrganization ? (
            <Switch>
              <Redirect
                from={OrganizationAdminRoutes.PROFILE}
                to={`${OrganizationAdminRoutes.ACCOUNT_DETAILS}/view/${userOrganization}/user/view/${profile.uid}`}
              />

              <Route path={OrganizationAdminRoutes.STUDENTS} component={Students}/>
              <Route exact path={OrganizationAdminRoutes.PROFILE} component={Profile}/>
              <Route path={`${OrganizationAdminRoutes.ACCOUNT_DETAILS}`} component={Organizations}/>

              <Redirect to={OrganizationAdminRoutes.STUDENTS}/>
            </Switch>
          ) : (
            <Switch>
              <Route path={AdminRoutes.STUDENTS} component={Students}/>
              <Route exact path={AdminRoutes.PROFILE} component={Profile}/>

              <Route path={AdminRoutes.USERS} component={Users}/>

              <Route exact path={`${match.url}/study/preview`} component={Preview}/>
              <Route exact path={`${match.url}/study/edit`} component={EditQuestion}/>
              <Route exact path={`${match.url}/study/new`} component={NewQuestion}/>
              <Route exact path={`${match.url}/study`} component={Study}/>
              <Redirect from={`${match.url}/study`} to={`${match.url}/study`}/>

              <Route exact path={`${match.url}/test/new`} component={NewQuestion}/>
              <Route exact path={`${match.url}/test/preview`} component={Preview}/>
              <Route exact path={`${match.url}/test/edit`} component={EditQuestion}/>
              <Route exact path={`${match.url}/test`} component={Test}/>
              <Redirect from={`${match.url}/test`} to={`${match.url}/test`}/>

              <Route exact path={`${match.url}/free/new`} component={NewQuestion}/>
              <Route exact path={`${match.url}/free/preview`} component={Preview}/>
              <Route exact path={`${match.url}/free/edit`} component={EditQuestion}/>
              <Route exact path={`${match.url}/free`} component={Free}/>
              <Redirect from={`${match.url}/free`} to={`${match.url}/free`}/>

              <Route path={`${match.url}/organizations`} component={Organizations}/>

              <Route exact path={`${match.url}/faq`} component={StudentFAQ}/>

              <Route path={`${match.url}`} component={Dashboard}/>
            </Switch>
          )
        }

      </Container>
    </>
  );
};

export default AdminPathway;
