import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { Card } from '../../containers/Card';
import { MainScreen } from '../../containers/Main';
import AvatarCard from '../../components/AvatarCard';
import { updateStudent, sendOutNewUserEmail } from '../../store/actions/studentActions';
import StatsRowFlat from '../../data/StatsRowFlat';
import ProfileDetails from '../../components/ProfileDetails';
import ErrorBoundary from '../../components/ErrorBoundary';
import Study from './Study';
import LoadingModal from '../../components/LoadingModal';
import { TStudent } from '../../../types';
import { AdminRoutes } from '../../_CONS/routes';
import { RootState } from '../../store/createReduxStore';
import { ChangeEvent } from 'react';
import { Screen, useStyles } from './StyledStudent';
import useUser from '../../hooks/useUser';
import BackButton from '../../elements/BackButton';
import Tabs from '../../elements/Tabs';
import Tab from '../../elements/Tab';

type TProps = {};

type TParams = {
  id?: string,
  studentId?: string,
}

const Student: React.FC<TProps> = (props: TProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<TParams>();
  const [firebaseStudent, getStudent] = useUser<TStudent>();

  const [tabIndex, setTabIndex] = useState(0);
  const [student, setStudent] = useState<TStudent | null>(null);

  const isAdmin = useSelector((state: RootState) => state.firebase.profile.token.claims.role === 'admin');

  useEffect(() => {
    if (params.studentId) {
      getStudent(params.studentId)
    }
  }, [])

  useEffect(() => {
    if (firebaseStudent.data && !firebaseStudent.isLoading) {
      const student = firebaseStudent.data;
      const initial = {
        total: 0,
        correct: 0,
        wrong: 0,
      };

      setStudent({
        ...student,
        uid: params.studentId,
        all: student.all || initial,
        test: student.test || initial,
        study: student.study || initial,
      });
    }
  }, [firebaseStudent])

  const handleChange = (event: any, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const resendEmail = () => {
    dispatch(sendOutNewUserEmail(student));
  };

  const onUpdateStudent = (newStudent: Partial<TStudent>) => {
    dispatch(updateStudent({
      ...student,
      ...newStudent,
    }));
  };

  if (!params.studentId) {
    return <Redirect to={`${AdminRoutes.DASHBOARD}`} />
  }

  return (
    <>
      <Screen>
        <LoadingModal open={firebaseStudent.isLoading} />

        <MainScreen
          spacing={3}
          title="Student Profile"
          leftSideItem={<BackButton onClick={() => history.goBack()} />}
        >
          {!!student && (
            <>
              <Grid item xs={12} md={4}>
                <Grid container spacing={3} direction={'column'}>
                  <Grid item>
                    <AvatarCard user={student} />
                  </Grid>
                  <Grid item />
                </Grid>
              </Grid>
              <Grid item xs>
                <Card>
                  <Tabs
                    value={tabIndex}
                    //@ts-ignore
                    onChange={handleChange}
                  >
                    <Tab label="PROFILE" />
                    <Tab label="ACTIVITY" />
                    <Tab label="STUDY INFO" />
                  </Tabs>

                  {tabIndex === 0 && (
                    <>
                      <ErrorBoundary>
                        <ProfileDetails
                          student={{
                            ...student,
                            uid: params.studentId
                          }}
                          adminView={isAdmin}
                          actionUpdate={onUpdateStudent}
                          actionEmail={resendEmail}
                        />
                      </ErrorBoundary>
                    </>
                  )}
                  {tabIndex === 1 && (
                    <>
                      {student ? (
                        <ErrorBoundary>
                          <StatsRowFlat
                            label="Overall"
                            stats={student.all}
                          />

                          <StatsRowFlat
                            label="Study Aids"
                            stats={student.study}
                          />
                          <StatsRowFlat
                            label="Practice Exams"
                            stats={student.test}
                          />
                        </ErrorBoundary>
                      ) : (
                        <div className={classes.emptyState}>
                          No Student Data
                        </div>
                      )}
                    </>
                  )}
                  {tabIndex === 2 && (
                    <>
                      {student ? (
                        <ErrorBoundary>
                          {
                            //@ts-ignore
                            <Study student={student} />
                          }
                        </ErrorBoundary>
                      ) : (
                        <div className={classes.emptyState}>
                          No Student Data`
                        </div>
                      )}
                    </>
                  )}
                </Card>
              </Grid>
            </>
          )}
        </MainScreen>
      </Screen>
    </>
  );
}

Student.displayName = 'StudentView';

export default Student;
