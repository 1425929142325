import styled from 'styled-components/macro';
import { color, util, fontType } from '../../styles/style';

export const EditBannerWrapper = styled.div`
  background-color: ${color.grey};
  border-width: 1px;
  border-color: ${color.blueDarkest};
  padding: ${util.padding};
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueDarkest};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
export const EditBannerContainer = styled.div`
  border-color: ${color.blueDarkest};
  padding: 0;
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueDarkest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const EditBannerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const BannerImageArea = styled.div`
  flex-basis: 20%;
  max-width: 100px;
`;
export const BannerContentArea = styled.div`
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
`;

export const BannerButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ButtonLabel = styled.div`
  display: flex;
  flex-basis: 30%;
`;
export const ButtonUrl = styled.div`
  display: flex;
  flex-basis: 70%;
`;
