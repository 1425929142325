import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { mediaMinDevice } from '../../styles/responsive';
import { Card, CardHeader, CardContent } from '../../containers/Card';

const InfoCardFooter = styled.div`
  display: block;
  & a {
      margin-bottom: 1rem
  }
  & a:last-child {
      margin-bottom: 0
  }
  ${mediaMinDevice.mobileL} {
      display: flex;
      justify-content: space-between;
      & a {
          margin-bottom: 0
      }
  }
`;

type TProps = {
  title?: string,
  txt?: string | ReactNode,
  divider?: boolean
}

const InfoCard: React.FC<TProps> = ({ title = '', txt = '', divider = false, children }) => {
  const info = typeof txt === 'string' ? (
    <Typography>{txt}</Typography>
  ) : txt;

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {info}
      </CardContent>
      {divider ? <Divider /> : ''}
      <CardContent>
        <InfoCardFooter>{children}</InfoCardFooter>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
