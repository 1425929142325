import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { MainScreen } from '../../../containers/Main';
import { Card, CardContent } from '../../../containers/Card';
import QuestionsTable from '../../../components/QuestionsTable';
import Button from '../../../elements/Button';
import Select from '../../../elements/Select';
import {
  resetQuestionSection,
  setQbank,
  addQuestion,
} from '../../../store/actions/questionActions';
import { free as qbanks } from '../../../_CONS/qbanks';

class Free extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qpath: 'free/study-civ',
      qbank: 'study-civ',
    };
    this.addNewQuestion = this.addNewQuestion.bind(this);
  }

  componentDidMount() {
    const { section } = this.props;

    if (section !== 'free') {
      const qbank = qbanks[0].value;
      const qpath = `free/${qbank}`;
      const uObj = {
        section: 'free',
        level: 'free',
        qbank,
        view: 'list',
      };

      this.props.resetQuestionSection(uObj).then(() => {
        this.setState({ qpath, qbank });
      });
    }
  }

  handleChange = event => {
    const qbank = event.target.value;
    const qpath = `free/${qbank}`;
    const uObj = {
      section: 'free',
      level: 'free',
      qbank,
      qpath,
    };

    this.props.setQbank(uObj).then(() => {
      this.setState({ qbank, qpath });
    });
  };

  addNewQuestion(e) {
    const { history, level, section, qbank } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: section,
      level: level,
      qbank: qbank,
      view: 'new',
      qid: '',
    };
    this.props.addQuestion(sObj)
      .then(() => history.push(`/admin/${section}/new`));
  }
  render() {
    const { qpath, qbank } = this.state;
    return (
      <MainScreen
        title='Free Questions'
        rightSideItem={
          <Button
            onClick={this.addNewQuestion}
            color='primary'
            variant='contained'
            aria-label='Add Question'
          >
            Add Question
          </Button>
        }
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Select
                value={this.state.qbank || ''}
                onChange={this.handleChange}
                name='qbank'
                displayEmpty
              >
                <MenuItem value='' disabled>
                  <em>Select Question Bank</em>
                </MenuItem>

                {qbanks.map(item => {
                  return (
                    <MenuItem
                      key={item.value}
                      name={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </CardContent>
            <Divider />
            {qpath && (
              <QuestionsTable
                qpath={qpath}
                qbank={qbank}
                section='free'
                level='free'
              />
            )}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}

Free.propTypes = {
  section: PropTypes.string,
  level: PropTypes.string,
  qbank: PropTypes.string,
  status: PropTypes.string,
};

const mapStateToProps = state => ({
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  section: state.questionStatus.section,
  status: state.questionStatus.status,
});

const mapDispatchToProps = {
  resetQuestionSection,
  setQbank,
  addQuestion,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Free);
