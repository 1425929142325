import Button from '../../../../elements/Button';
import theme from '../../../../styles/theme';
import styled from 'styled-components/macro';
import { mediaMaxDevice, mediaMinDevice } from '../../../../styles/responsive';
import { Box, Grid } from '@material-ui/core';

export const WideButton = styled(Button)`
  width: 15em !important;
`;

export const BulkButton = styled(WideButton)`
  margin-left: ${theme.spacing(3)}px;
`;

export const ResetButton = styled(Button)`
  background-color: transparent;
  margin-left: ${theme.spacing(3)}px;
  &:{
    background-color: transparent;
  }
`;

export const AddButton = styled(WideButton)`
    margin-left: ${theme.spacing(3)}px;
`;

export const ButtonsGroup = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: flex-start;

  ${mediaMinDevice.tabletL} {
    justify-content: flex-end;
  }
`;

export const ImportRow = styled(Grid)`
  ${mediaMaxDevice.tabletL} {
    flex-direction: column-reverse;
  }
`;

export const ImportButtonWrapper = styled(Grid)`
  justify-content: flex-end;
  
  ${mediaMaxDevice.tabletL} {
    justify-content: flex-start;
    margin-bottom: 1rem;
    flex-direction: column-reverse;
  }
`;
