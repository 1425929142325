import React, { useEffect, useState } from 'react';
import { MainScreen } from '../../../../containers/Main';
import { Grid } from '@material-ui/core';
import StudentImport from '../../../../components/StudentImport';
import { TOrganization } from '../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/createReduxStore';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SectionTitle } from './StyledImportStudents';
import GenericTable from '../../../../components/GenericTable';
import firebase from '../../../../store/api/firebaseAdmin';
import { get } from 'lodash';
import moment from 'moment';
import { sortObjects } from '../../../../utils/sort';
import { AdminRoutes } from '../../../../_CONS/routes';
import { Redirect, useHistory } from 'react-router';
import BackButton from '../../../../elements/BackButton';

type TImportStundets = {}

type THistoricalImport = {
  name: string,
  date: string,
  link: string,
};

const ImportStundets: React.FC<TImportStundets> = (props) => {
  const history = useHistory();
  const [historicalImports, setHistoricalImports] = useState<THistoricalImport[]>([]);

  const profile = useSelector((state: RootState) => state.firebase.profile);
  const organization: TOrganization | null = useSelector((state: RootState) => state.firestore.data.userOrganization);

  const toConnect = profile.token.claims.role === 'admin' && profile.organization ? [
    { collection: 'organizations', doc: profile.organization, storeAs: 'userOrganization' },
  ] : [];

  useFirestoreConnect(toConnect);

  useEffect(() => {
    if (organization) {
      getHistoricalImports();
    }
  }, [organization])

  const getHistoricalImports = async () => {
    if (organization) {
      const orgId = organization.id;

      try {
        const ref = firebase.storage().ref().child(`imports/${orgId}`);
        const imports = await ref.listAll();
        const metadataPromises = imports.items.map((item) => item.getMetadata());

        Promise.allSettled(metadataPromises).then((metadata) => {
          setHistoricalImports(imports.items.map((item, index) => {
            return {
              name: item.name,
              link: `https://firebasestorage.googleapis.com/v0/b/${item.bucket}/o/imports%2F${orgId}%2F${item.name}?alt=media`,
              date: get(metadata[index], 'value.timeCreated'),
            }
          }).sort((a, b) => sortObjects(a, b, 'date', 'desc')));
        })

      } catch (e) {
        console.log(e);
      }
    }

    return [];
  }

  const onFinishImport = () => {
    getHistoricalImports();
  }

  if (profile.token.claims.role !== 'admin' || !profile.organization || organization && organization.id !== profile.organization) {
    return <Redirect to={AdminRoutes.STUDENTS} />
  }

  return (
    <MainScreen
      title="Import Students"
      leftSideItem={<BackButton onClick={history.goBack} />}
      spacing={3}
    >
      <Grid item xs={12}>
        <SectionTitle variant={'subtitle1'}>Add Students to the Organization</SectionTitle>
        <StudentImport
          organizationId={organization ? organization.id : ''}
          onFinish={onFinishImport}
        />
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={12}>
          <SectionTitle variant={'subtitle1'}>Historical Student Imports</SectionTitle>
        </Grid>

        <Grid item md={6} sm={12}>
          <GenericTable
            colHeaders={[
              {
                id: 'name',
                label: 'File Name',
                selector: 'name',
              },
              {
                id: 'date',
                label: 'Date Imported',
                selector: 'date',
              }
            ]}
            data={historicalImports.map((item) => ({
              name: <a target={'_blank'} href={item.link} download>{item.name}</a>,
              date: moment(item.date).format('MMMM Do YYYY, h:mm:ss a'),
            }))}
          />
        </Grid>
      </Grid>

    </MainScreen>
  )
}

export default ImportStundets;
